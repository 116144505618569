export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        contest_requests :'', 
        contest_request :'', 

    },
    
    getters : {
        contest_requests: (state )=> state.contest_requests,
        contest_request: (state )=> state.contest_request,
    
    },


    mutations : { 
        setcontest_requests :(state,contest_requests ) => (state.contest_requests = contest_requests), 
        setcontest_request :(state,contest_request ) => (state.contest_request = contest_request),
    
    },

    actions : {

        async get_user_contest_requests( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_user_contest_requests')
            return new Promise((resolve, reject) => {
            axios.post("contests/user_requests/all?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setcontest_requests', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async approve_user_contest_request( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'approve_user_contest_request')
            return new Promise((resolve, reject) => {
            axios.post("contests/user_requests/approve/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontest_requests', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async reject_user_contest_request( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'reject_user_contest_request')
            return new Promise((resolve, reject) => {
            axios.post("contests/user_requests/reject/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setcontest_requests', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },




    }
}