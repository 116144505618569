let routes = [

    {
        path: "/auth/",
        props:true,
        component: () => import("@/views/auth/main.vue"),


        children: [
          {
            path: "login",
            name: "login",
            props:true,
            component: () => import("@/views/auth/login.vue"),
            meta: {
             title: 'Login to Your Dashboard'
            }
          },

          {
            path: "register",
            name: "register",
            props:true,
            component: () => import("@/views/auth/register.vue"),
            meta: {
             title: 'Create An Account'
            }
          },

        ]
    },

]
export default routes;