import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import notifications  from './modules/notifications'
import contests  from './modules/contests'
import contest_categories  from './modules/contest_categories'
import contest_category_sessions  from './modules/contest_category_sessions'
import contest_requests  from './modules/contest_requests'
export default createStore({
  modules: {
    auth, 
    utils,
    notifications,
    contests,
    contest_categories,
    contest_category_sessions,
    contest_requests

  },
    
  plugins: [createPersistedState(
    {
      key : 'smarttraderuganda.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
