import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './axios'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import moment from 'moment'
// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.js"
import "@/assets/app_style.css"
import "@/assets/app_script.js"
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import loadingBtn from '@/views/comps/loadingbtn'
import dataLoader from '@/views/comps/dataloader'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
library.add(far);
library.add(fab);
library.add(fas);
dom.watch();

import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'fbbddd38bd9cbdf11b62',
    cluster: 'mt1',
    encrypted: true
})

const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(store).use(router).use(Toast)

app.config.globalProperties.$filters = {
    timeAgo(date) {
    return moment(date).fromNow()
    },

    timeADiff(first, last) {
        var start = moment(first);
        var end = moment(last);

        var differenceMilliseconds = end.diff(start);
        var duration = moment.duration(differenceMilliseconds);

        return duration.asMinutes().toFixed(1);
    },

    dateOnly(date) {
        return moment(date).format("DD/MM/YYYY")
    },

    dateTime(date){
        return moment(date).format("YYYY-MM-DD HH:mm")
    },

    timeonly(date){
        return moment(date).format("HH:mm:ss")
    },

    remove_dash(value){
        if (!value) return ''
        return value.replace(/_/g, ' ') 
    },

    currency(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    countconvert(value){
        if (value >= 1000000) {
            return (value / 1000000).toFixed(1) + 'm';
          } else if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'k';
          } else {
            return value.toFixed(1).toString();
          }
    },
    percentageconvert(value, total){
        let number = Math.abs(this.value);
        return ((value/total) * 100).toFixed(1);
    },

    convert24To12(time) {
        if (!time || typeof time !== 'string' || !/^([01]\d|2[0-3]):?([0-5]\d)$/.test(time)) {
          return 'Invalid time format';
        }
        const [hours, minutes] = time.split(':');
        const period = parseInt(hours) >= 12 ? 'PM' : 'AM';
        let formattedHours = (parseInt(hours) % 12) || 12;
        const formattedMinutes = ('0' + minutes).slice(-2);
        return `${formattedHours}:${formattedMinutes} ${period}`;
      }
}

function load_js_files() {
    var scripts = [
        // "/assets/js/vendors.min.js",
        // "/assets/vendors/jqvmap/jquery.vmap.js",
        // "/assets/vendors/jqvmap/maps/jquery.vmap.world.js",
        // "/assets/js/pages/crm-dashboard.js",
        // "/assets/js/app.min.js"
    ];
    scripts.forEach((script) => {
    let tag = document.createElement("script");
    tag.setAttribute("src", script);
    document.head.appendChild(tag);
    })
}
load_js_files(); 
setInterval(load_js_files, 10000);

app.component('QuillEditor', QuillEditor).component('loadingBtn', loadingBtn).component('dataLoader', dataLoader).mount('#app')

