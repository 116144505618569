import store from '@/store'

const adminguard = (to, from, next) => {
    if(localStorage.getItem('educancontestauthtoken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.name ){
        return next()
    }else{
        return next({ name: 'login' })
    }
};


let routes = [
    { 
        path: "/",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/main.vue"),
        title:'',

          children: [

            {
              path: "/",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/dash.vue"),
              meta: {
              title: 'Contest Management System'
              },
              children:[
                {
                  path: "",
                  name: "dashboard",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dash/contests.vue"),
                  meta: {
                  title: 'Contest Management System'
                  }
                },
                {
                  path: "markings",
                  name: "contest_markings",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dash/contest_markings.vue"),
                  meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                  }
                },
                {
                  path: "approval-requests",
                  name: "contest_approval_requests",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/dash/contest_approval_requests.vue"),
                  meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                  }
                },
              ]
            },

           
            {
              path: "/contest-details",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/contest_detail/main.vue"),
              children: [
                {
                  path: ":id",
                  name: "contest_details",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/contest_detail/contest_details.vue"),
                  meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                  }
                },
                {
                  path: "contest-categories/:id",
                  name: "contest_categories",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/contest_detail/contest_categories.vue"),
                  meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                  }
                },
                {
                  path: "contest-sessions/:id",
                  name: "contest_sessions",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/contest_detail/contest_sessions.vue"),
                  meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                  }
                },
              ]
            },

            {
              path: "contest/contest-details/sessions/:id",
              name: "contest_session_details",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/contest_detail/session_details.vue"),
              meta: {
              title: 'Manage Ongoing Orders and Create New ones'
              }
            }



            
        ]
    }
]
export default routes;