export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        sessions :'', 
        session :'', 

    },
    
    getters : {
        sessions: (state )=> state.sessions,
        session: (state )=> state.session,
    
    },


    mutations : { 
        setsessions :(state,sessions ) => (state.sessions = sessions), 
        setsession :(state,session ) => (state.session = session),
    
    },

    actions : {

        async create_contest_session( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_contest_session')
            return new Promise((resolve, reject) => {
            axios.post("contests/sessions/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_contest_session( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_contest_session')
            return new Promise((resolve, reject) => {
            axios.post("contests/sessions/update/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_contest_session( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_contest_session')
            return new Promise((resolve, reject) => {
            axios.post("contests/sessions/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsession', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async change_contest_session_status( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_session_status' + id)
            return new Promise((resolve, reject) => {
            axios.post("contests/sessions/status/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
 

        async delete_contest_session({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_contest_session')
            return new Promise((resolve, reject) => {
                axios.post(`contests/sessions/delete/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setcontest', response.data.res)
                        toast.success("session Request Deleted Successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },



        ///questions
        async create_contest_session_questions( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_contest_session_questions')
            return new Promise((resolve, reject) => {
            axios.post("contests/questions/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_contest_session_questions( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_contest_session_question')
            return new Promise((resolve, reject) => {
            axios.post("contests/questions/update/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_contest_session_questions({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_contest_session_questions')
            return new Promise((resolve, reject) => {
                axios.post(`contests/questions/delete/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setcontest', response.data.res)
                        toast.success("session Request Deleted Successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },
    }
}