export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        categories :'', 
        category :'', 

    },
    
    getters : {
        categories: (state )=> state.categories,
        category: (state )=> state.category,
    
    },


    mutations : { 
        setcategories :(state,categories ) => (state.categories = categories), 
        setcategory :(state,category ) => (state.category = category),
    
    },

    actions : {

        async create_contest_category( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_contest_category')
            return new Promise((resolve, reject) => {
            axios.post("contests/categories/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_contest_category( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_contest_category')
            return new Promise((resolve, reject) => {
            axios.post("contests/categories/update/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async change_contest_category_status( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_category_status' + id)
            return new Promise((resolve, reject) => {
            axios.post("contests/categories/status/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
 

        async delete_contest_category({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_contest_category')
            return new Promise((resolve, reject) => {
                axios.post(`contests/categories/delete/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setcontest', response.data.res)
                        toast.success("category Request Deleted Successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },
    }
}